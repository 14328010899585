.main {  
  flex-direction: column;
  gap: 10px;
  border: 0;
  &.active {
    display: flex;
  }
  .mainScreen {
    border-radius: 15%;    
    width: 435px;
    height: 435px;
    background-color: rgb(8, 8, 8);    
    scrollbar-width: none;  /* Firefox */
  }
  .footer {
    position: absolute;
    bottom: -50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    & > div {
      border: 0;
      background-color: #6fe5de;
      border-radius: 5px;
      padding: 0 5px;
      font-size: 24px;
      cursor: pointer;
      &:active {
        font-size: 28px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}



.dropbox {
  width: 40%;
  font-size: 80%;
}
