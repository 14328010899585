@tailwind base;
@tailwind components;
@tailwind utilities;


*,
*::before,
*::after {
  box-sizing: border-box;
  /* position: relative; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-responsive-modal-overlay {
  background-color: transparent !important;
}

.react-responsive-modal-modal {
  box-shadow: none !important;
  background-color: #222424 !important;
}


*::-webkit-scrollbar {
  width: 0.4vw;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0.2vw;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  outline: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 0.2vw;
}

@font-face {
  font-family: "nokia";
  src: local("nokia"),
    url("./assets/font/nokia.ttf") format("truetype");
}

@font-face {
  font-family: "lcd";
  src: local("lcd"),
    url("./assets/font/lcd.ttf") format("truetype");
}

@font-face {
  font-family: "thin-pixel";
  src: local("thin-pixel"),
    url("./assets/font/thin-pixel.ttf") format("truetype");
}

.absolute-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}