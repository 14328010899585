.AltScreen {
  & .fadeIn {
    opacity: 1;
    transition: 3s;
    transition-timing-function: ease-in-out;
  }
  & .fadeOut {
    opacity: 0;
    transition: 1s;
    height: 0 !important;
    transition-timing-function: ease-in-out;
  }
  .content {
    border: 3px solid #38baa9ec;
    width: 330px;
    height: 330px;
    border-radius: 4%;
    position: relative;
    overflow: hidden;
    background-color: rgb(8, 8, 8);
    & > * {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    & .Shopping {
      &-img {
        width: 100%;
      }
    }
    & .Custom {
      background-color: rgb(8, 8, 8);
      color: white;
      text-align: left;
      &-title {
        font-size: 25px;
      }
      &-content {
        font-size: 12px;
      }
    }
    & .Admin {
      & .slider-content {
        position: absolute;
        width: 336px;
        height: 324px;
        top: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .slider-description {
          background-color: white;
          border: 0;
          border-radius: 30px;
          font-size: 40px;
          padding: 0 20px;
          max-width: 280px;
        }
      }
    }
    & .processing {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: gray;
      &-end {
        background-color: aquamarine;
        border: 0;
        border-radius: 20px;
        padding: 0 30px;
        cursor: pointer;
      }
    }
    & .duck-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 100%;
      .owner-address {
        color: white;
        font-size: 15px;
      }
    }
  }
  .footer {
    position: relative;
    margin-top: 40px;
    .btn {
      position: absolute;
      top: -6px;
      left: 20px;
      width: 163px;
      height: 48px;
      background-color: gray;
      border-radius: 4px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      &.bg-info {
        background-color: #6fe5de;
      }
      &.bg-danger {
        background-color: #dc3545;
      }
      &.bg-yellow {
        background-color: yellow;
      }
      &.white {
        color: white;
      }
    }
  }
}
