.DrawingTool {
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  width: 435px;
  height: 435px;
  border-radius: 15%;
  // overflow: auto;  
  &.active {
    display: flex;
  }
  & .btn {
    border: 0;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: rgb(240, 159, 159);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    &.disabled {
      background-color: gray;
      cursor: default;
    }
  }
  .drawing-canvas-container {
    width: 100%;
    height: 100%;
    border-radius: 15%;
    display: relative;
  }
  .drawing-canvas {
    width: 100%;
    height: 100%;
    border-radius: 2%;
    cursor: url("../../../../../public/assets/images/pencil.png"), default;
  }
  .bottom {
    position: fixed;
    bottom: -54px;
    display: flex;
    justify-content: center;
    gap: 25px;
  }
  .left {
    position: fixed;
    left: -12%;
    width: 10%;
    top: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
  }
  .right {
    position: fixed;
    right: -12%;
    width: 10%;
    bottom: 22%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
  }
}

.ColorPicker {
  width: 110px;
  height: 115px;
  border-radius: 13px;
  cursor: pointer;
  & .react-colorful {
    width: 0;
    height: 0;
  }
  & .react-colorful__saturation {
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 10%;
    border: 2px solid #1e1b21;
    overflow: hidden;
  }
  & .react-colorful__hue {
    position: fixed;

    width: 398px;
    height: 24px;
    top: 163px;
    left: 178px;
    border: 1px solid rgba(0, 0, 0, 0.404);
    border-right: 0;
    border-radius: 10%;
    clip-path: polygon(-10% 0, 100% 0, 95.5% 100%, -1% 100%);
    
  }
  & .react-colorful__saturation-pointer {
    width: 20px;
    height: 20px;
    border: 1px solid white;
    opacity: 0.75;
    border-radius: 50%;
    cursor: grab;
    &:hover {
      opacity: 1;
    }
    &:active {
      cursor: grabbing;
    }
  }

  & .react-colorful__hue-pointer {
    border-radius: 0px;
    cursor: grab;
    border: 0;
    width: 8px;
    border: 6px solid #5c5c5c;
    height: 28px;
    &:active {
      cursor: grabbing;
    }
  }
}
