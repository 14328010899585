

.LoadingScreen {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
  background-color: rgb(8, 8, 8);
  width: 435px;
  height: 435px;
  border: 2px solid rgba(255, 255, 255, 0.383);
  border-radius: 15%;
  padding: 20px;
  font-size: 16px;
  text-align: center;
  align-items: center;  
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50% 10%;
  &.hidden {
    display: none;
  }
  & * {
    // font-family: PressStart2P-Regular;
    
    // font-family: 'Quadrit', sans-serif;
    font-family: 'nokia', monospace,sans-serif;                                                
    
  }
  .description {
    color: black;
  }
  .btn-connect {
    
    
      
    cursor: pointer;    
  }
}
