body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  background-color: #00c7ff;
}

.app-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .machine-container {
    margin-top: 7%;
    aspect-ratio: 14.25 / 9;
    position: relative;
  }
}

.fixed-center {
  @apply fixed;
  @apply top-1/2;
  @apply left-1/2;
  @apply transform;
  @apply -translate-x-1/2;
  @apply -translate-y-1/2;  
}

.text-shadow {
  text-shadow: 2px 2px #0000005b;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
}

.black-screen {
  background-color: rgb(8, 8, 8);
}

.pixel-font {
  font-family: "nokia", monospace, sans-serif;
}

.pixel-font-thin {
  font-family: "thin-pixel", monospace, sans-serif;
}

.lcd-font {  
  font-family: "lcd", monospace, sans-serif;    
}

.graph-bg {  
  background-color: #f69e46;
}

.testy-font {
  font-family: "testy";
}

.copy-font {
  font-family: "copy";
}

.phat-purple {
  -webkit-text-stroke: 1px rgb(255, 105, 253);
  -webkit-text-stroke: 2px rgb(23, 255, 15);
  color: rgb(37, 28, 28);

  $top: #9a7097;
  $bottom: #9a20e6;

  text-shadow: 1px 0px 1px $top, 0px 1px 1px $bottom, 2px 1px 1px $top,
    1px 2px 1px $bottom, 3px 2px 1px $top, 2px 3px 1px $bottom, 4px 3px 1px $top,
    3px 4px 1px $bottom, 5px 4px 1px $top, 4px 5px 1px $bottom, 6px 5px 1px $top,
    5px 6px 1px $bottom, 7px 6px 1px $top;
}

.shiny {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.5508578431372548) 6%,
    rgba(204, 204, 204, 0) 75%
  );
}

.title-image {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
}

.drop-shadow {
  filter: drop-shadow(10px 10px 10px rgb(0 0 0 / 0.5));
}

.drop-glow {
  filter: drop-shadow(0 0 10px rgb(90, 34, 244));
}

.circle-button {
  box-shadow: 1px 2px 2px 2px #b53c24;
  background: linear-gradient(to bottom, #ff912a 5%, #ff6e25 100%);
  background-color: #ff8040;
  margin-right: 1px;
  border-width: 2px;
  height: 42px;
  width: 42px;

  border: 3px solid #ffffff37;
  border-top: 0;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;  
  text-decoration: none;
  text-shadow: 0px 1px 0px #96423c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pressed {
  position: relative;
  box-shadow: 1px 1px 0px 2px #b53c24;
  border: 3px solid #ffffff1c;
  background: linear-gradient(to bottom, #f06824 5%, #ec5523 100%);
  top: 1px;
}

.circle-button:active {
  position: relative;
  box-shadow: 1px 1px 0px 2px #b53c24;
  border: 3px solid #ffffff1c;
  background: linear-gradient(to bottom, #f06824 5%, #ec5523 100%);
  top: 1px;
}

.circle-button.small {  
  height: 25px;
  border-width: 1px;
  width: 25px;
  box-shadow: 2px 1px 1px 0px #b53c24;
}

.scroll-button {  
  border: 3px solid #ffffff1c;
  height: 25px;
  border-width: 1px;
  width: 25px;
  box-shadow: 1px 3px 3px 0px #b53c24;
  cursor: grab;
}

.scroll-button:active {
  cursor: grabbing;
}

.pixel-art {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}



.circle-button.small:active {
  position: relative;
  box-shadow: 1px 1px 1px 0px #b53c24;
  background: linear-gradient(to bottom, #f06824 5%, #ec5523 100%);
  top: 1px;
  left: 1px;
}

.pill-button {
  background: linear-gradient(to bottom, #b5b5b5 5%, #b5b5b5 100%);
  background-color: #ff8040;
  margin-right: 1px;
  border-width: 2px;
  border: 3px solid #ffffff37;
  border-top: 0;
  cursor: pointer;
  color: #474747;
  font-family: Arial;  
  text-decoration: none;
  text-shadow: 0px 1px 0px #a6a6a6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pill-button:active {
  position: relative;  
}

.status-panel {  
  position: fixed;
  width: 421px;
  top: 159px;
  left: 174px;
  
  clip-path: polygon(-10% 0, 100% 0, 95.5% 100%, -1% 100%);
}

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container__document {
  margin: 1em 0;
}

.container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.container__document .react-pdf__message {
  padding: 20px;
  color: white;
}

.right {
  position: fixed;
  right: -13%;
  width: 10%;
  bottom: 28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
}

.bottom {
  position: absolute;
  bottom: -4%;
  width: 100%;
  display: flex;
  justify-content: space-between;  
}

.inner-shadow {
  -webkit-box-shadow: inset 8px 5px 8px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 8px 5px 8px -1px rgba(0, 0, 0, 0.5);
  box-shadow: inset 8px 5px 8px -1px rgba(0, 0, 0, 0.5);
}

.shimmer {          
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
}


