.mainWrap {
    z-index: 999999;
    text-align: right;
    width: 95px;
    top: -86px;
    overflow-y: auto;
    right: -288px;
    position: absolute;
    color: white;
    font-size: 16px;
    font-family: 'nokia', monospace,sans-serif;
}