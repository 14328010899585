.social-icon {
  stroke-width: 1px;
  // stroke-opacity: 0.75;
  @apply w-6 h-6 lg:w-10 lg:h-10;
  @apply fill-[#fe7a42] hover:fill-[#ffac59] transition;
  // @apply fill-[#ffac59] hover:fill-[#ff5206] transition;
  filter: drop-shadow(3px 5px 3px rgb(0 0 0 / 0.2));
}

.orange {
  stroke-width: 1px;
  @apply fill-[#fe7a42] hover:fill-[#ffac59] transition;
  filter: drop-shadow(3px 5px 3px rgb(0 0 0 / 0.2));
}

.footer-link {
  @apply text-[#fe7a42] hover:text-[#ffac59] transition;
  filter: drop-shadow(3px 5px 3px rgb(0 0 0 / 0.2));
}